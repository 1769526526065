import React, { useEffect, useState } from 'react';
import './UpdateDate.css';
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
// import { Paper, Typography, Box } from '@mui/material';
import { useContext } from 'react';
import DataContext from '../Context/DataContext';
import GetDate from './GetDate';
import { format } from 'date-fns';
import axios from 'axios';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import Title from '../Title/Title';
import { Button, Select, MenuItem, InputLabel, Stack, Paper, Typography, Box } from '@mui/material';
import SelectMedicineOptions from './SelectMedicineOptions';
import SelectIVFOptions from './SelectIVFOptions';
import Toast from '../Toast/Toast'
import { TOAST_PROPERTIES } from '../Toast/ToastProperties';
import SemenTypeRadioGroup from './SemenTypeRadioGroup';


const UpdateDate = ({ item }) => {
  const { InitiateRefresh } = useContext(DataContext);
  const navigate = useNavigate();

  const[dateValue, setDateValue]=useState();
  const[isLoading, setLoadingValue]=useState(false);
  // const [fetchError, setFetchError] = useState(null);
  const [medicineValue, setMedicineValue]=useState("none");
  const [ivfdocValue, setIvfdocValue]=useState("none");
  const [optionValue, setOptionValue] = useState(0);
  const [toastList, setToastList]=useState([]);
  const [toastMsg, SetToastmsg]=useState('');
  const [semenValue, SetSemenValue]=useState('Normal');
//  console.log(item);

const routeParams = useParams();

useEffect(()=>{
// console.log(item);
console.log({ routeParams });
console.log(routeParams.cowid);
});

console.log(optionValue);
const getsemenValue = (value)=>{
  // console.log(value);
  SetSemenValue(value);
}

  const getMedicineChange = (value)=>{
    // console.log(value);
    setMedicineValue(value);
  }

  const getIvfDocChange = (value)=>{
    // console.log(value);
    setIvfdocValue(value);
  }

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();


  const ShowToast = (type, msg)=> {
    const list = TOAST_PROPERTIES.find((toast) => toast.title.toLowerCase() === type);
    SetToastmsg(msg);
    setToastList([...toastList, list]);
    setInterval(() => {
      setToastList([]);
  }, 15000);

  };
  // useEffect(() => {
  // console.log(`option value i use effect ${optionValue}`);
  //   if(optionValue === '4') {
  //     console.log("is true");
  //     setShowDewormingOptions(1);
  //   } else {
  //     console.log("is false");
  //     setShowDewormingOptions(0);
  //   }

  // }, [optionValue])

  const onSubmit = async (data) => {
    let update_text="";
    // console.log(dateValue);
    let date_value = format(new Date(dayjs(dateValue)), 'yyyy-MM-dd');

    if(optionValue == 0){
      ShowToast('warning', 'Update Option not selected');
      return;
    }

    if(!dateValue) {
      ShowToast('warning', 'Date not selected');
      return;
    }

    if(optionValue == 4 && (medicineValue ==='' || medicineValue ==='none')) {
      ShowToast('warning', 'Deworming medicine not selected');
      return;
    } else if (optionValue == 4) {
      update_text = `Deworming done using ${medicineValue} on ${dateValue}`;
    }

    if(optionValue == 3 && (ivfdocValue==='' || ivfdocValue ==='none')){
      ShowToast('warning', 'IVF doctor not selected');
      return;
    } else if (optionValue == 3){
      update_text = `IV or Fertilisation done using ${semenValue} semen by ${ivfdocValue} on ${dateValue}`
    }

      // console.log(`option value i use effect ${optionValue}`);
      // console.log(`ivfdoc value is ${ivfdocValue}`);
      // console.log(`medicine value ist ${medicineValue}`);
      // console.log(`date  value is ${dateValue}`);
      // console.log(`Semen value is ${semenValue}`);

    // return;
    setLoadingValue(true);
    // console.log("data: " + JSON.stringify(data));
    // console.log("this date " + dateValue);
    const updatedDatePost = {
      cowid: data.cowid,
      updateText: (ivfdocValue !== '' && ivfdocValue !== 'none') ? update_text :
        (medicineValue !=='' && medicineValue !== 'none') ? update_text :"none",
      datetype: optionValue,
      datevalue: date_value };
    // console.log("date: " + format(new Date(dayjs(dateValue)), 'yyyy-MM-dd'))
    // console.log(JSON.stringify(updatedDatePost));
    // const url = `https://localhost:44383/repos/api/DairyFarm/UpdateDate`;
    const url = `https://www.sasyavatagro.com/repos/api/DairyFarm/UpdateDate`;
    // return;

    try{
    const response = await axios.post(
      url,
          updatedDatePost);
    // console.log(JSON.stringify(response));
    InitiateRefresh();
    ShowToast('success', 'Successfully added new Cow');
    } catch (err){
      // setFetchError(err.message);
      // console.log(err.message);
      ShowToast('warning', 'Some issue updating, please check and try again');
    }finally{
      setLoadingValue(false);
      navigate(-1);
    //  window.location='/';
      // navigate('/', { replace: true });
    }
  };

  const dateChangeHandler = (newdate)=>{
  setDateValue(newdate);
  // console.log("new date " + newdate);
}

const onSelectDateOptionsChange=(event)=>{
  setIvfdocValue('');
  setMedicineValue('');
  // console.log(event.target.value);
  setOptionValue(event.target.value);
}


return (
  <>
    <Title title={"Update Date"}/>
    <Stack spacing={2} >
  <Paper className="updatedate" elevation={11} sx={{top:'300px'}}>

  {isLoading && <CircularProgress color="secondary" />}
  {!isLoading &&
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className="form-control">
      <Typography variant='h6'>
        <label name="cowid">Update Date for Cow Id: { routeParams.cowid }</label>
      </Typography>
      <input type="hidden"
          // hidden='true'
          name="cowid"
           {...register('cowid')}
          value ={ routeParams.cowid }/>
    </div>
    <div className="form-control">
      <InputLabel id="simple-select-label">Update Type</InputLabel>
          <Typography>        </Typography>
          <Select
            labelId="datetype"
            id="datetype"
            label="Update Type"
            value={optionValue}
            onChange={ onSelectDateOptionsChange }
            >
            <MenuItem value="5">Stop Milking</MenuItem>
            <MenuItem value="4">Deworming</MenuItem>
            <MenuItem value="3">IV</MenuItem>
            <MenuItem value="2">Heat Date</MenuItem>
            <MenuItem value="1">Delivery Date</MenuItem>
            <MenuItem value="0">Select Type...</MenuItem>
          </Select>

    </div>


    {
      optionValue ==='4' ?  <SelectMedicineOptions onSelectChange={getMedicineChange}/>
      : null
    }

    {
      optionValue==='3' ?
      <>
      <SelectIVFOptions onSelectChange={getIvfDocChange} />
      <SemenTypeRadioGroup onSelectChange={getsemenValue} />
      </>
      : null
    }


    <div className="form-control">
      {/* <Typography> */}
    {/* <label className='radioitemfordate' htmlFor="datevalue">Date</label></Typography> */}
      <GetDate name="datevalue"
        // dateValue={dateValue}
        fs={dateChangeHandler} />
        <input
        // type="text"
        type="hidden"
          // hidden = 'true'
          name="txtdatevalue"
           {...register('datevalue')}
           value = { dateValue }
          // value ={ format(dateValue, 'dd/MM/yyyy') }
          />
          </div>

        <Box sx={{display:'flex', justifyContent:'space-evenly'}}>

              {/* <button type="submit">Update</button> */}
              <Button type="submit" variant='contained'>Update</Button>
              {/* <button onClick={() => navigate(-1)}>Cancel</button> */}
              <Button variant='contained' color='warning' onClick={() => navigate(-1)}>Cancel</Button>

              </Box>
    </form> }
    </Paper>
        <Box display="flex" justifyContent='center' sx={{width:"150px"}}>
              <Toast
                toastList={toastList}
                position="bottom-left"
                message={toastMsg}
            />
        </Box>
              </Stack>
    </>
  );
}

export default UpdateDate

