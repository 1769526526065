import { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip'; 
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Badge, Box } from '@mui/material';
import IndicatorSimple from '../Indicator/IndicatorSimple';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Link } from 'react-router-dom';
import HighlightBoard from '../Indicator/HighlightBoard';
// import Api from '../GetData/Api';


    const CowCardItem = ({item, fs}) => {
    const [expanded, setExpanded] = useState(false);
    const [isidset, setId]=useState(0);

    // const [isMainImageLoading, setMainImageLoading]= useState(false);
    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
      })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      })
      );
    
    const handleClickAway = () => {
        if(isidset){
           setExpanded(!expanded);
        }
        };        

    const handleExpandClick = (id, img) => {
        var expandedValue = !expanded;
        setExpanded(!expanded);
        setId(1);
        // setMainImageLoading(true);
        // var img_id = "idimg_" + id;
        const url = 'https://sasyavatagro.com/img/farmimg/' + img ;
        // console.log(img_id);        
            if(expandedValue){
        //     document.getElementById("idimg_" + id).src= '../../images/cow_img-loading.png';
                setTimeout(()=>{
                    document.getElementById("idimg_" + id).src= url;                
        //     console.log(document.getElementById(img_id).height);
        // }
        }, 2000);
    }
      setExpanded(!expanded);
    };          

//console.log(item.cowid);
  return (
    <Card variant='elevation' sx={{marginBottom:'20px', marginLeft:'5px', marginRight:'5px', 
        boxShadow:'3px 3px 5px 1px lightgrey', borderRadius:'15px'}}>
        <CardContent>            
            <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={6}>
                <Box display="flex" justifyContent="left">
                    {item.dayssincedeworming <= 90 && 
                    <IndicatorSimple size={item.dayssincedeworming} cls="dewormingOk" 
                    msg="De-worming"/>}
                    {item.dayssincedeworming > 90 && 
                    <IndicatorSimple size={item.dayssincedeworming} cls="dewormingNok" 
                    msg="De-worming"/>}            
                </Box>
            </Grid>
            <Grid item xs={6} display="flex" 
            direction={'column'}
                    paddingRight={'10px'}
                    justifyContent="flex-end"  fontSize={'12px'}>
                {
                    (()=>{
                        if(item.dayssinceheat > item.dayssinceiv){
                            return <Typography textAlign='right' paddingRight="10px" fontWeight="bold" variant='body2' fontSize={'12px'}>                    
                            Last IV Date </Typography>
                        } else {
                            return <Typography textAlign='right' paddingRight="10px" fontWeight="bold" variant='body2' fontSize={'12px'}>                    
                            Last Heat Date </Typography>
                        }
                    })()
                }
                {/* <br/>                 */}
                <Box display="flex" 
                    paddingRight={'10px'}
                    justifyContent="flex-end"  fontSize={'12px'}>
                    {
                    (item.dayssincelastdelivery === 999 & item.dayssinceiv === 999) ? "Not Applicable" :
                    (item.dayssinceheat > item.dayssinceiv) ?
                    item.ifdate.substring(0,10) : item.heatdate.substring(0,10)
                    }                
                </Box>                
            </Grid>
            <Grid item xs={6}>                
                <Box display="flex" justifyContent="flex-start" >
                    {(()=>{
                        if(item.status !== 'Adult Female'){
                            // No componenet
                        } else if (item.dayssincelastdelivery === 999 & item.dayssinceiv === 999)
                        {
                            // Not valid
                        } else if (item.dayssincelastdelivery < item.dayssinceiv & item.dayssincelastdelivery < 21)
                        {
                            return <IndicatorSimple size={item.dayssincelastdelivery} cls="indicatorGreen" 
                            msg="Since delivery"/>;
                        } else if (item.dayssinceheat < item.dayssinceiv)
                         {
                            return <IndicatorSimple size={item.dayssinceheat} cls="indicatorOrange" 
                            msg="Since Heat"/>;
                        }                        
                        else if(item.dayssincelastdelivery < item.dayssinceiv & item.dayssincelastdelivery >= 21)
                        {
                            return <IndicatorSimple size={item.dayssincelastdelivery} cls="indicatorOrange" msg="Since delivery"/>;
                        } if(item.dayssinceiv > 210){
                            return <><div onClick={()=> fs(item.cowid, item.isPregnant)}><IndicatorSimple size={item.dayssinceiv} cls="indicatorBlue" 
                            msg="Since Iv"  verified={item.isPregnant} v_color='lightgrey'/> 
                                </div><Badge badgeContent={item.ivrepeat} color="primary"></Badge></>;
                        } else {
                            return <><div onClick={()=> fs(item.cowid, item.isPregnant)}>
                                    <IndicatorSimple size={item.dayssinceiv} cls="indicatorGreen" 
                                    msg="Since Iv" verified={item.isPregnant} v_color='lightGreen'/>
                                    </div><Badge badgeContent={item.ivrepeat} color="primary"></Badge></>;
                        }
                    })()
                    }
                </Box>                                         
            </Grid>
            <Grid item xs={6} display="flex" 
            direction={'column'}
                    paddingRight={'10px'}
                    justifyContent="flex-end"  fontSize={'12px'}>
            <Typography paddingRight={'6px'} textAlign={'right'} fontWeight={'bold'} variant='body2'  fontSize={'12px'}>
                Delivery Date </Typography>                
                <Box display="flex"
                    align='right' 
                    justifyContent="flex-end"
                    fontSize={'12px'}
                    textAlign="right"
                    paddingRight={'10px'}>                
                    {(item.dayssincelastdelivery === 999) ? "Not Applicable" :
                    item.lastdeliverydate.substring(0,10)}                
                </Box>
            </Grid>
            <Grid item xs={12}>
                <HighlightBoard cowid={item.cowid} isverified={item.isVerified} ismilking={item.isMilking} />
            </Grid>
            </Grid>
        </CardContent>
        <ClickAwayListener onClickAway={handleClickAway}> 
        <div >
        <CardActions className= {item.status === "Heifer - Female" ? 'cardActionCowList-Heifer' : 
                                item.status === "Bull" ? 'cardActionCowList-Bull' : 
                                item.isMilking === 0 ? 'cardActionCowList-NonMilking' : 'cardActionCowList' }
        disableSpacing >
        <Grid container maxWidth="sm"
        flex='1' 
        alignSelf='center'        
        paddingLeft="10px"
        >             
            <Chip color='primary' 
            label= {item.tag + "-" +item.status} 
             variant='contained'
             sx={{
                fontSize:'12px'
             }}/> 
        </Grid>
        <Link to={'/cowdetail/'+ item.cowid}>
            <Button 
                variant={'contained'}
                color={'primary'}
                disableRipple            
                size="small" 
                sx={{margin:"10px",  fontSize:'12px'}}>Details
            </Button> 
        </Link>           
            <ExpandMore
                expand={expanded}
                onClick={()=>{handleExpandClick(item.cowid, item.image1)}}
                aria-expanded={expanded}
                aria-label="show more">
                <ExpandMoreIcon />
            </ExpandMore>    
        </CardActions> 
    <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>                        
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '50vh' }}>
            <Grid item xs={4}>
                 <CardMedia
                    component="img"
                    height="594"         
                    id={'idimg_' + item.cowid}
                    alt="Cow"                           
                     image={require('../../images/cow_img.png')}      
                    // style={styles.media}
                     sx={{ objectFit: "contain" }}
                     className='expandedImage' />             
                </Grid>                
            </Grid>
            
        </CardContent>
    </Collapse></div>
    </ClickAwayListener>
    </Card>
  )
}

export default CowCardItem