import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UpdateMilking = ({selectlist, fs}) => {
    let navigate = useNavigate();
    
    const [tagId, setTagId] = React.useState('');
    const [cowtag, setCowTag]= React.useState('');
    const [cowId, setCowId]= React.useState('');

    const handleChange = (event) => {      
      const values = event.target.value.split(',');

      // var index = event.target.selectedOptions[0];
      // console.log(getElementbyId('demo-simple-select'));
      // console.log(values[0]);
      setTagId(event.target.value);
      setCowTag(values[1]);
      setCowId(values[0]);
      // console.log(event.target.innerHtml);
      // fs(event.target.value);
    };

    const UpdateMilkingValue =()=>{
        navigate("/UpdateMilk/" + tagId, { replace: false });
    }
  
  return (
    <Box sx={{ position:'relative', width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-around', 
    backgroundColor:'lightgrey', alignItems:'center', borderRadius:'20px' }}>
      <InputLabel id="demo-simple-select-label">
        Select Tag
        </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        size='small'
        id="demo-simple-select"
        value={tagId}
        label="Select Tag"
        // options={selectlist.map(item=>({label: item.cowid, value:item.tag}))}
        onChange={handleChange}
        >
        {
            selectlist.map((item, index)=>(                
                <MenuItem key={index} value={item.cowid+','+item.tag}>{item.tag}</MenuItem>
                ))
        }
        </Select>        
        <Button size='small' variant='contained' onClick={UpdateMilkingValue}>Go</Button>        
  </Box>
  );
}

export default UpdateMilking